<template>
  <div>
    <v-card class="px-8 pt-4 pb-10" v-if="!error">
      <div
          style="font-size: .9rem; color: #4a4a4a; padding-left: 2px; padding-bottom: 4px; text-transform: uppercase; display: flex">
        <div>Новини</div>
        <v-spacer></v-spacer>
        <div>{{ create_date | formatDate }}</div>
      </div>
      <v-divider></v-divider>
      <div style="font-size: 1.8rem; text-transform: uppercase; font-weight: 500; padding-bottom: 18px; padding-top: 12px">
        {{ title }}
      </div>
      <div>
        <v-img
            :src="getFile()"
            contain
            height="300px"
        />
      </div>
      <div class="py-6" v-html="compiledMarkdown"></div>
    </v-card>
    <v-card class="px-8 pt-4 pb-10" v-else min-height="200">
      <div style="font-size: 2rem;" class="secondary--text text-uppercase text-center">Помилка</div>
      <div style="font-size: 12rem;" class="secondary--text text-uppercase text-center">404</div>
      <div style="font-size: 1rem;" class="text-uppercase text-center">
        Нажаль, такої новини не існує. Поверніться до списку та обреіть необхідну...
      </div>
      <div>

      </div>
    </v-card>
  </div>
</template>

<script>
import siteAPI from "@/utils/axios/personalSite/site"
import {BASE_URL_AXIOS} from "@/utils/axios";
import marked from 'marked'
marked.setOptions({
  silent: true,
  pedantic: false,
  gfm: true,
  breaks: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
  xhtml: false,
  mangle: true
})

export default {
  name: "NewsView",
  data() {
    return {
      filepath: '',
      title: '',
      body: '',
      create_date: null,
      error: false,
      publicPath: process.env.BASE_URL,
    }
  },
  computed: {
    compiledMarkdown() {
      return marked(this.body);
    },
  },
  methods: {
    getFile() {
      if (!this.filepath) return `${this.publicPath}img/icons/no_image.png`
      return `${BASE_URL_AXIOS}/${this.filepath}`
    },
    fetch() {
      this.filepath = ''
      this.title = ''
      this.body = ''
      this.create_date = null
      this.error = false

      const organization_slug = this.$route.params.organization || ''
      const news_id = this.$route.params.news_id || 0
      if (organization_slug) {
        siteAPI.get_news_by_id({organization_slug, news_id})
            .then(response => response.data)
            .then(data => {
              this.filepath = data.filepath
              this.title = data.title
              this.body = data.body
              this.create_date = data.create_date
              this.error = data.error
            })
            .catch(() => {
              this.$router.push({name: 'NotFoundSite'})
            })
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped lang="scss">

</style>